import MAPLAYER_TYPE from "./map/maplayertype.const";

const getfilterConfig = function (geomType) {
  let config = {};
  switch (geomType) {
    case MAPLAYER_TYPE.MONO_COLOR_LINE_STRING: //linestring MonoColor
      config.type = "line";
      // config.minzoom = 1;
      // config.maxzoom = 13;
      config.layout = { "line-cap": "round" };
      config.paint = {
        "line-width": {
          stops: [
            [8, 3],
            [11, 3],
            [16, 3]
          ]
        },
        "line-opacity": 1
      };
      break;
    case MAPLAYER_TYPE.POINT: //Point
      config.type = "circle";
      config.paint = {
        "circle-radius": 5,
        "circle-color": ["get", "col"],
        "circle-opacity": 1
      };
      break;
    case MAPLAYER_TYPE.POLYGON3D: //3D Polygon
      config.type = "fill-extrusion";
      config.paint = {
        "fill-extrusion-color": ["get", "col"],
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12.9,
          0,
          12.9 + 0.05,
          ["get", "ext"]
        ],
        "fill-extrusion-base": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12.9,
          0,
          12.9 + 0.05,
          0
        ],
        "fill-extrusion-opacity": 0.7
      };
      break;
    case MAPLAYER_TYPE.MULTI_COLOR_LINE_STRING: //linestring MultiColor
      config.type = "line";
      // config.minzoom = 1;
      // config.maxzoom = 13;
      config.layout = { "line-cap": "round" };
      config.paint = {
        "line-color": ["get", "col"],
        "line-width": {
          stops: [
            [8, 3],
            [11, 3],
            [16, 3]
          ]
        },
        "line-opacity": 1
      };
      break;
    default:
      config.type = "line";
      config.minzoom = 1;
      config.maxzoom = 13;
      config.layout = { "line-cap": "round" };
      config.paint = {
        "line-color": ["get", "col"],
        "line-width": {
          stops: [
            [8, 3],
            [11, 3],
            [16, 3]
          ]
        },
        "line-opacity": 1
      };
      break;
  }
  return config;
};

export default { getfilterConfig };
