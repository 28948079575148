<template>
  <div id="auscultation-campaign-map" class="pa-4">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <v-btn
          class="mt-5 ml-2 mr-2 m_brown"
          icon
          :to="{ name: 'project', params: { id: workspaceId } }"
        >
          <v-icon large>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <div>
          <h1>{{ $cap(auscultationCampaign.name) }}</h1>
          <i>Carte</i>
        </div>
      </div>
      <div>
        <HighButtonSlot
          class="ma-2"
          :_to="{
            name: 'auscultation-campaign-dashboard',
            params: { id: auscultationCampaign.id }
          }"
        >
          <v-icon left dark> mdi-file-document-outline </v-icon>
          Tableau de bord
        </HighButtonSlot>
      </div>
    </div>
    <div class="loading_container" v-if="!isMapDisplayed">
      <span>Vos résultats seront bientôt disponibles</span>
    </div>
    <v-card class="map_container" v-if="!loading && isMapDisplayed" outlined>
      <auscultation-map
        :layersList="layersList"
        :filters="filters"
        :selectedLayers="selectedLayers"
        @closeMenu="closeMenu"
        ><auscultation-menu
          ref="menu"
          v-if="layersList.length"
          :layersList="layersList"
          @layerSelected="layerSelected"
        ></auscultation-menu>
        <auscultation-map-filtre
          :layersLegend="layersLegend"
          :selectedLayers="selectedLayers"
          @filterChanged="changeFilter"
        ></auscultation-map-filtre
      ></auscultation-map>
    </v-card>
  </div>
</template>

<script>
import AuscultationMap from "../../components/auscultation/map/Auscultation.map.vue";
import AuscultationMenu from "../../components/auscultation/map/Auscultation.map.menu.vue";
import AuscultationMapFiltre from "../../components/map/Map.filtre.vue";
import MAPLAYER_STATUS from "../../utils/map/maplayerstatus.const";

export default {
  components: {
    AuscultationMap,
    AuscultationMenu,
    AuscultationMapFiltre
  },
  name: "AuscultationCampaignMap",
  data() {
    return {
      auscultationCampaign: { name: "", id: "", status: "" },
      layersList: [],
      selectedLayers: [],
      filters: [],
      loading: true,
      workspaceId: "",
      layersLegend: []
    };
  },
  mounted() {
    this.$api.auscultationCampaigns
      .findById(this.$route.params.id)
      .then(campaign => {
        this.auscultationCampaign = campaign;
        this.workspaceId = campaign.workspace.id;
        this.getLayersList(campaign.id);
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    changeFilter(newVal) {
      let filterChanged = newVal.map(layerLegend => {
        let filters = layerLegend.legends
          .filter(el => el.selected)
          .map(legend => {
            if (legend.selected) {
              if (legend.valueExact && legend.valueExact.length > 0) {
                let allFilters = ['any'];
                legend.valueExact.forEach(val => {
                  allFilters.push(
                    ["==",
                      ["to-string", ["get", layerLegend.column]],
                      ["to-string", `${val}`]
                    ]
                  )
                });
                return allFilters;
              } else {
                let filter = ["all"];
                filter.push([
                  legend.excludeMin ? ">" : ">=",
                  ["get", layerLegend.column],
                  legend.min
                ]);
                filter.push([
                  legend.excludeMax ? "<" : "<=",
                  ["get", layerLegend.column],
                  legend.max
                ]);
                return filter;
              }
            }
          });
        return { id: layerLegend.id, filters: filters };
      });
      this.filters = filterChanged;
    },
    closeMenu(isOpen) {
      this.$refs.menu.changeExpansionPanel(isOpen);
    },
    getLayersList(campaignId) {
      this.$api.mapLayers.findByCampaign(campaignId).then(async layers => {
        this.layersList = layers;
        this.loading = false;
        this.layersLegend = await this.getLayersLegends(this.layersList);
      });
    },
    async getLayersLegends(layers) {
      let layersLegends = [];
      let getLayerLegend = async layer => {
        if (layer.status === MAPLAYER_STATUS.GENERATION_FINISHED) {
          const legends = await this.$api.mapLayers.getLegends(layer.id);
          return {
            id: layer.jsonKey + "_Id",
            name: layer.name,
            legends,
            column: layer.layerColumn
          };
        } else {
          return null;
        }
      };
      for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (!layer.isContainer) {
          let legendLayer = await getLayerLegend(layer);
          if (null !== legendLayer) {
            layersLegends.push(legendLayer);
          }
        } else {
          let childLayersLegends = await this.getLayersLegends(layer.children);
          if (childLayersLegends.length > 0) {
            layersLegends.push(...childLayersLegends);
          }
        }
      }
      return layersLegends;
    },
    layerSelected(selectedLayers) {
      this.selectedLayers = selectedLayers;
    }
  },
  computed: {
    isMapDisplayed() {
      return (
        ["engineering", "finished", "archived"].indexOf(
          this.auscultationCampaign.status
        ) != -1
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";
#auscultation-campaign-map {
  padding: 15px 15px 0 15px;
  height: 100%;
}
.map_container {
  border-radius: 4px !important;
  overflow: hidden;
  position: relative;
  margin: 0 8px 0 8px;
  height: 85vh;
}
.loading_container {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .map_diapo_on {
//   width: 50% !important;
// }
// .diaporama_map_diapo_on {
//   width: 50%;
// }
// .diaporama_map_diapo_off {
//   display: none;
// }
</style>
