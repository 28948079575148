<template>
  <div id="Auscultation_Map">
    <diaporama-map
      :class="{
        diaporama_map_diapo_on: isDiaporamaOpen,
        diaporama_map_diapo_off: !isDiaporamaOpen
      }"
      :map="map"
      :photosGeoJson="photosData"
      :isOpen="isDiaporamaOpen"
      :decoration="true"
    ></diaporama-map>

    <!-- <div id="mapContainer" class="auscultation_map"> -->
    <div
      id="mapContainer"
      :class="{
        auscultation_map_diapo_on: isDiaporamaOpen,
        auscultation_map: !isDiaporamaOpen
      }"
    >
      <slot></slot>
      <div id="geocoder" class="geocoder" ref="geocoder"></div>

      <map-nav
        :map="map"
        :draw="draw"
        :isTableDisplayed="isTableDisplayed"
        :tableHeight="tableHeight"
        @styleChanged="resetLayers"
        :measuringTapeContainerId="'distance'"
        :isMapInReloading="isMapInReloading"
      ></map-nav>
      <v-btn
        depressed
        class="diaporama_btn"
        color="#f1f1f1"
        v-if="isDiaporamaButtonDisplayed"
        @click="switchDisplayDiapoBtn(!isDiaporamaOpen)"
        >{{ isDiaporamaOpen ? "Fermer diaporama" : "Afficher diaporama" }}
        <v-icon color="black">{{
          isDiaporamaOpen ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon></v-btn
      >
      <div class="bottom-left-gizmo">
        <div id="distance" class="distance-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../../service/auth";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import configUtils from "../../../utils/config.utils";
import popupUtils from "../../../utils/map/popup.utils";
import MapPopup from "../../popup/Auscultation.map.popup.vue";
import store from "@/store";
import * as turf from "@turf/turf";
import mapNav from "../../map/Map.nav.vue";
import DiaporamaMap from "../../map/Diaporama.map.vue";
import MAPLAYER_STATUS from "../../../utils/map/maplayerstatus.const";
import MAPLAYER_TYPE from "../../../utils/map/maplayertype.const";
import MAPLAYER_ZOOM from "../../../utils/map/maplayerzoom.const";

export default {
  components: { mapNav, DiaporamaMap },
  name: "AuscultationMap",
  props: ["selectedLayers", "filters", "layersList"],
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoiYWJhaWxseTUiLCJhIjoiY2t2dGd1ZXFoMHJhODJubHl2OG5ueGo2byJ9.KC7fg_kMFLW-FMsgoU7UPQ",
      location: null,
      map: {},
      lastZoom: 12,
      draw: {},
      mapLoaded: false,
      defaultCenter: [2.213749, 46.227638],
      photosData: {},
      tableHeight: 250,
      isTableDisplayed: false,
      isMapInReloading: false,
      isDiaporamaOpen: false,
      isDiaporamaButtonDisplayed: false,
      popup: null
    };
  },
  beforeMounted() {},
  mounted() {
    this.mapInit();
    this.$root.$on("flyTo", this.flyTo);
    this.$root.$on("closeCurrent", () => {
      if (this.popup) {
        this.popup.closeFunc();
        this.popup = null;
      }
    });
  },
  methods: {
    flyTo(coordinates, zoom = 15) {
      this.map.jumpTo({
        center: coordinates,
        essential: true,
        zoom: zoom
      });
    },
    async mapInit() {
      let mapStyle = "mapbox://styles/mapbox/streets-v11";
      if (store.state.campaign.current.mapStyle === "satellite")
        mapStyle = "mapbox://styles/mapbox/satellite-v9";

      mapboxgl.accessToken = this.accessToken;

      let center = this.defaultCenter;
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(pos => {
          center = [pos.coords.longitude, pos.coords.latitude];
        });
      } else if (store.state.campaign.current.center)
        center = store.state.campaign.current.center;

      this.map = new mapboxgl.Map({
        container: "mapContainer",
        style: mapStyle,
        center,
        zoom: 5,
        transformRequest: (url, resourceType) => {
          if (
            resourceType === "Tile" &&
            url.startsWith(process.env.VUE_APP_BASE_URL)
          ) {
            return {
              url: url,
              headers: { Authorization: "Bearer " + auth.getJwt() }
            };
          }
        }
      });

      let geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: this.$t("search"),
        flyTo: { duration: 0 }
      });
      this.$refs["geocoder"].appendChild(geocoder.onAdd(this.map));

      const that = this;
      this.map.on("load", function () {
        that.map.getCanvas().style.cursor = "wait";
        that.initLayers(that.layersList).then(() => {
          that.mapLoaded = true;
          that.map.getCanvas().style.cursor = "";
          let visibleLayers = that.getVisibleDataLayersIdList(that.layersList);
          if (visibleLayers.length == 0) {
            visibleLayers = that.getDataLayersIdList(this.layersList);
          }
          that.updateLayerBounds(visibleLayers, true);
        });
      });
      this.map.on("zoom", () => {
        let currentZoom = this.map.getZoom();
        this.reload3DLayers(this.layersList);
        this.lastZoom = currentZoom;
      });
      this.lastZoom = this.map.getZoom();
    },
    centerlizeOnFeature(featureData) {
      const center = turf.center(featureData);
      this.flyTo(center.geometry.coordinates, 12);
      this.$store.commit("campaign/set_current", {
        center: center.geometry.coordinates
      });
    },
    async updateLayerBounds(visibleLayers, centerlized = true) {
      let bounds = null;
      if (visibleLayers && visibleLayers.length > 0) {
        let boundingBox = await this.$axios.get(
          "map-layers/bounding-box?map-list=" + visibleLayers.join(",")
        );
        if (
          null !== boundingBox &&
          null !== boundingBox.data &&
          Object.keys(boundingBox.data).length !== 0
        ) {
          bounds = turf.bbox(boundingBox.data);
          if (centerlized) {
            this.map.fitBounds(bounds, { duration: 0 });
          }
        }
      }
      if (null !== bounds) {
        this.$store.commit("campaign/set_current", {
          bounds: bounds
        });
      }
    },
    addMapLayer(layer) {
      let layerId = layer.jsonKey + "_Id";
      let currentZoom = this.map.getZoom();
      let tileSource = "tile";
      let geomType = layer.geomType;
      if (geomType == MAPLAYER_TYPE.POLYGON3D) {
        if (currentZoom < MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL) {
          tileSource = "linestring";
          geomType = MAPLAYER_TYPE.MULTI_COLOR_LINE_STRING;
        } else {
          tileSource = "polygon";
          geomType = MAPLAYER_TYPE.POLYGON3D;
        }
      }

      const config = configUtils.getfilterConfig(geomType);

      if (this.selectedLayers.indexOf(layerId) !== -1) {
        config.layout = {
          visibility: "visible"
        };
      } else {
        config.layout = {
          visibility: "none"
        };
      }

      this.map.addLayer({
        id: layerId,
        source: layer.jsonKey + "_Source",
        "source-layer": tileSource,
        ...config
      });
      this.handlePointerClick(this.map, layer);
    },
    async initLayers(list) {
      let initLayer = layer => {
        if (layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED) {
          return;
        }
        var tileTpl = `${process.env.VUE_APP_BASE_URL}map-layers/{map-layer-id}/tiles/{z}_{x}_{y}.pbf`;
        this.map.addSource(layer.jsonKey + "_Source", {
          type: "vector",
          tiles: [tileTpl.replace("{map-layer-id}", layer.id)]
        });

        this.addMapLayer(layer);
      };
      for (const layer of list) {
        if (layer.isContainer) {
          await this.initLayers(layer.children);
        } else {
          initLayer(layer);
        }
      }
      if (this.mapLoaded) {
        this.filters.forEach(el => {
          this.map.setFilter(el.id, ["any", ...el.filters]);
        });
      }
    },
    handlePointerClick(map, layer) {
      map.on("mouseenter", layer.jsonKey + "_Id", function () {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", layer.jsonKey + "_Id", function () {
        map.getCanvas().style.cursor = "";
      });
      var imageSettings;
      this.$axios
        .get("map-layers/" + layer.id + "/imageSettings")
        .then(response => {
          imageSettings = response.data;
        });
      map.on("click", layer.jsonKey + "_Id", async e => {
        const gid = e.features[0].properties.gid;
        const itemData = await this.$axios.get(
          "map-layers/" + layer.id + "/data/" + gid,
          { showLoader: false }
        );
        this.switchDisplayDiapoBtn(false);
        this.loadPhotos(itemData.data, imageSettings).then(() => {
          this.popup = popupUtils.generatePopup(
            e.lngLat.lng,
            e.lngLat.lat,
            itemData.data,
            map,
            MapPopup
          );
        });
      });
      map.on("click", () => {
        this.isDiaporamaButtonDisplayed = this.isDiaporamaOpen;
        this.$emit("closeMenu", false);
      });
    },
    async loadPhotos(itemData, imageSettings) {
      if (imageSettings.length === 0) {
        this.isDiaporamaButtonDisplayed = false;
        return;
      }
      let id = itemData[imageSettings[0].dstColumnName].val;
      this.photosData = await this.$api.images.findBySettingsAndId(
        imageSettings[0].id,
        id
      );
      this.isDiaporamaButtonDisplayed =
        Object.keys(this.photosData).length !== 0;
    },
    getDataLayersIdList(layers) {
      let layersIdList = [];
      layers.forEach(layer => {
        if (!layer.isContainer) {
          layersIdList.push(layer.id);
        } else {
          let childLayerIdList = this.getDataLayersIdList(layer.children);
          layersIdList.push(...childLayerIdList);
        }
      });
      return layersIdList;
    },
    getVisibleDataLayersIdList(layers) {
      let layersIdList = [];
      this.selectedLayers.forEach(selectedLayer => {
        layers.forEach(layer => {
          if (!layer.isContainer) {
            let layerId = layer.jsonKey + "_Id";
            if (selectedLayer.indexOf(layerId) !== -1) {
              layersIdList.push(layer.id);
            }
          } else {
            let childLayerIdList = this.getVisibleDataLayersIdList(
              layer.children
            );
            layersIdList.push(...childLayerIdList);
          }
        });
      });
      return layersIdList;
    },
    reload3DLayers(layers) {
      let zoomLevel = this.map.getZoom();
      let reload3DLayer = layer => {
        if (
          layer.isContainer ||
          layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED
        ) {
          return;
        }
        if (layer.geomType == MAPLAYER_TYPE.POLYGON3D) {
          let currentMapLayerId = layer.jsonKey + "_Id";
          let currentMapLayer = this.map.getLayer(currentMapLayerId);
          let layerVisibility = this.map.getLayoutProperty(
            currentMapLayerId,
            "visibility"
          );
          let layerTileSource = currentMapLayer.sourceLayer;
          if (
            currentMapLayer &&
            layerVisibility === "visible" &&
            ((layerTileSource == "polygon" &&
              zoomLevel < MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL) ||
              (layerTileSource == "linestring" &&
                zoomLevel >= MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL))
          ) {
            this.map.removeLayer(currentMapLayerId);
            this.addMapLayer(layer);
            this.filters.forEach(el => {
              if (el.id === currentMapLayerId) {
                this.map.setFilter(el.id, ["any", ...el.filters]);
              }
            });
          }
        }
      };
      layers.forEach(layer => {
        if (layer.isContainer) {
          this.reload3DLayers(layer.children);
        } else {
          reload3DLayer(layer);
        }
      });
    },
    resetLayers() {
      this.isMapInReloading = true;
      this.initLayers(this.layersList).then(() => {
        let visibleLayers = this.getVisibleDataLayersIdList(this.layersList);
        if (visibleLayers.length == 0) {
          visibleLayers = this.getDataLayersIdList(this.layersList);
        }
        this.updateLayerBounds(visibleLayers, true);
      });
    },
    switchDisplayDiapoBtn(newValue) {
      let canvas = this.map.getCanvas();
      if (!newValue) {
        this.isDiaporamaOpen = false;
        canvas.style.width = "100%";
      } else {
        this.isDiaporamaOpen = true;
        canvas.style.width = "100%";
      }
      setTimeout(() => this.map.resize(), 10);
    },
    updateLayers(newLayersList, layers) {
      let updateLayer = layer => {
        if (
          layer.isContainer ||
          layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED
        ) {
          return;
        }
        let visibility = "none";
        if (newLayersList.indexOf(layer.jsonKey + "_Id") != -1) {
          visibility = "visible";
        }
        this.map.setLayoutProperty(
          layer.jsonKey + "_Id",
          "visibility",
          visibility
        );
      };
      layers.forEach(layer => {
        if (!layer.isContainer) {
          updateLayer(layer);
        } else {
          this.updateLayers(newLayersList, layer.children);
        }
      });
    }
  },
  computed: {
    updateEvent() {
      return this.mapLoaded && this.selectedLayers;
    },
    filterChanged() {
      console.log('Auscultation.map.vue::filterChanged', this.mapLoaded, this.filters);
      return this.filters;
    },
    filterEvent() {
      console.log('Auscultation.map.vue::filterEvent', this.mapLoaded, this.filters);
      return this.mapLoaded && this.filters;
    }
  },
  watch: {
    filterEvent: function () {
      if (this.mapLoaded) {
        this.filters.forEach(el => {
          this.map.setFilter(el.id, ["any", ...el.filters]);
        });
      }
    },
    updateEvent: function () {
      if (this.mapLoaded) {
        this.reload3DLayers(this.layersList);
        this.updateLayers(this.selectedLayers, this.layersList);
        let visibleLayers = this.getVisibleDataLayersIdList(this.layersList);
        if (visibleLayers.length == 0) {
          visibleLayers = this.getDataLayersIdList(this.layersList);
        }
        this.updateLayerBounds(visibleLayers, false);
      }
    }
  },
  beforeDestroy() {
    this.map.remove();
  }
};
</script>

<style lang="scss">
#mapContainer {
  overflow: hidden;
}
#Auscultation_Map {
  height: 85vh;
}

.auscultation_map {
  width: 100%;
  height: 85vh;
}
.geocoder {
  position: absolute;
  z-index: 1;
  width: 50%;
  left: 45%;
  top: 10px;
}
.mapboxgl-ctrl-geocoder {
  min-width: 10%;
}
.bottom-left-gizmo {
  position: absolute;
  bottom: 30px;
  left: 10px;
  z-index: 1;

  .distance-container > * {
    background-color: #fff;
    font-size: 16px;
    line-height: 18px;
    display: block;
    margin: 0;
    padding: 5px 10px;
    border-radius: 3px;
  }
}

.auscultation_map_diapo_on {
  width: 50% !important;
  height: 85vh;
  right: 0;
  position: absolute;
}
.diaporama_map_diapo_on {
  width: 50%;
  height: 85vh;
  left: 0;
  position: absolute;
  z-index: 1;
}
.diaporama_map_diapo_off {
  display: none;
}
.diaporama_btn {
  font-family: "Roboto";
  transform: rotate(-90deg);
  position: absolute;
  left: 0px;
  top: 65%;
  z-index: 1;
  transform-origin: 0% 0;
}
.mapboxgl-canvas {
  outline: none;
}
</style>
