<template>
  <div id="auscultation-campaign-dashboard" class="pa-4">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <v-btn
          class="mt-5 ml-2 mr-2 m_brown"
          icon
          :to="{ name: 'project', params: { id: workspaceId } }"
        >
          <v-icon large>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <div>
          <h1>{{ $cap(auscultationCampaign.name) }}</h1>
          <i v-if="auscultationCampaign.description">{{
            auscultationCampaign.description
          }}</i
          ><i v-else>Tableau de bord</i>
        </div>
      </div>
      <div>
        <MediumButtonSlot
          class="mr-2"
          v-if="
            auscultationCampaign.status === 'finishing' ||
            auscultationCampaign.status === 'finished' ||
            auscultationCampaign.status === 'archived'
          "
          @click="openGenerateLinkModale()"
          >Générer un lien public</MediumButtonSlot
        >
        <HighButtonSlot
          class="ma-2"
          :_to="{
            name: 'auscultation-campaign-map',
            params: { id: auscultationCampaign.id },
          }"
        >
          <v-icon left dark> mdi-map-outline </v-icon>
          Accéder à la carte
        </HighButtonSlot>
      </div>
    </div>
    <div class="message_container" v-if="!isDashboardDisplayed">
      <span>Vos résultats seront bientôt disponibles</span>
    </div>
    <div class="mr-2" v-else>
      <v-row>
        <v-col cols="12">
          <v-tabs
            color="$brown"
            v-model="tab"
            @change="
              createCharts();
              getStatisticTables();
              getStatisticTableData();
            "
            class="ml-2"
          >
            <v-tab v-for="(sectorValue, sectorKey) in chart" :key="sectorKey">{{
              sectorKey
            }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(sectorValue, sectorKey) in chart"
              :key="sectorKey + '_tab'"
            >
              <div class="filters_containers">
                <div class="pl-2">
                  <v-select
                    class="filterName"
                    :items="
                      Object.keys(sectorValue).map((el, index) => {
                        return { id: index, name: el };
                      })
                    "
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.id"
                    :label="sectorKey"
                    v-model="subTabs[sectorKey].road"
                    @change="
                      createCharts();
                      getStatisticTableData();
                    "
                  >
                  </v-select>
                </div>
                <div class="pl-2">
                  <v-select
                    class="filterDir"
                    v-if="
                      subTabs[sectorKey].isSelectDisplayed[
                        subTabs[sectorKey].road
                      ]
                    "
                    :items="
                      sectorValue[
                        Object.keys(sectorValue)[subTabs[sectorKey].road]
                      ].map((el) => el.sens)
                    "
                    label="Sens"
                    v-model="subTabs[sectorKey].sens[subTabs[sectorKey].road]"
                    @change="
                      createCharts();
                      getStatisticTableData();
                    "
                  >
                  </v-select>
                </div>
                <div>
                  <medium-button-slot @click="resetFilters" class="ml-2 mt-2"
                    >Réinitialiser</medium-button-slot
                  >
                </div>
              </div>
              <v-row>
                <v-col cols="12">
                  <v-tabs-items v-model="subTabs[sectorKey].road">
                    <v-tab-item
                      v-for="(roadValue, roadKey) in Object.keys(
                        sectorValue
                      ).map((road) => sectorValue[road])"
                      :key="roadKey"
                      class="all_container"
                    >
                      <div class="charts_container">
                        <v-card
                          outlined
                          v-for="chart in getGraphs(
                            roadValue,
                            subTabs[sectorKey].isSelectDisplayed[roadKey]
                              ? subTabs[sectorKey].sens[subTabs[sectorKey].road]
                              : subTabs[sectorKey].defaultSens[roadKey]
                          )"
                          :key="
                            sectorKey +
                            '_' +
                            Object.keys(sectorValue)[roadKey] +
                            '_' +
                            chart.id
                          "
                        >
                          <div
                            :id="
                              sectorKey +
                              '_' +
                              Object.keys(sectorValue)[roadKey] +
                              '_' +
                              chart.id
                            "
                            class="ChartBox"
                          >
                            <div class="Loading">
                              <v-progress-circular
                                :size="200"
                                :width="10"
                                color="#2c0703"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </div>
                          <div class="Commentary">
                            <span v-if="chart.comment != ''">COMMENTAIRE</span
                            ><br />
                            <p>
                              {{ chart.comment }}
                            </p>
                          </div>
                        </v-card>
                      </div>
                      <div class="statistic_tables_container">
                        <statistic-table
                          v-for="statisticTable in statisticTables"
                          :key="statisticTable.id"
                          :name="statisticTable.name"
                          :headers="
                            statisticTable.headers ? statisticTable.headers : []
                          "
                          :tableData="
                            statisticTable.tableData
                              ? statisticTable.tableData
                              : []
                          "
                          :footer="
                            'Linéaire ausculté : ' +
                            statisticTable.totalLinearLength +
                            ' mètres'
                          "
                        >
                        </statistic-table>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <Modale
      v-if="isGenerateLinkModaleOpen"
      title="Partager la campagne"
      @close="closeGenerateLinkModale"
      @validate="closeGenerateLinkModale"
    >
      <div class="generateLinkInput">
        <v-col md="8">
          <input ref="generatedLink" outlined disabled :value="url" />
        </v-col>
        <MediumButtonSlot @click="generateShareLink()"
          >Copier le lien</MediumButtonSlot
        >
      </div>
      <template slot="actions">
        <LowButtonSlot @click="closeGenerateLinkModale()">Fermer</LowButtonSlot>
      </template>
    </Modale>
  </div>
</template>

<script>
import amchartUtils from "../../utils/amcharts.utils";
import StatisticTable from "../../components/StatisticTable/StatisticTable.vue";
import COLUMN_NAME_CONSTANT from "../../utils/statistic-table/columnname.const";

export default {
  name: "AuscultationDashboard",
  components: { StatisticTable },
  data() {
    return {
      url:
        window.location.origin +
        "/share-link?token=eajklsdDZSdgfHsJjfEYhfe4lsdDhdgf5hSEafAlsdDhdgfk",
      isGenerateLinkModaleOpen: false,
      auscultationCampaign: { name: "", id: "", status: "" },
      workspaceId: "",
      chart: {},
      tab: {},
      subTabs: [],
      statisticTables: {},
    };
  },
  mounted() {
    this.$api.auscultationCampaigns
      .findById(this.$route.params.id)
      .then((campaign) => {
        this.auscultationCampaign = campaign;
        this.workspaceId = this.auscultationCampaign.workspace.id;
        this.getDataChart();
      });
  },
  methods: {
    async generateShareLink() {
      const guestUser = await this.$api.campaigns.inviteGuestUser(
        this.$route.params.id
      );
      this.url =
        window.location.origin + "/share-link?token=" + guestUser.token;
      this.copyTextToClipboard();
    },
    fallbackCopyTextToClipboard() {
      this.$refs.generatedLink.focus();
      this.$refs.generatedLink.select();

      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("unable to copy", err);
      }
    },
    copyTextToClipboard() {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard();
        return;
      }
      navigator.clipboard.writeText(this.url);
      this.$toast("success", "Lien copié dans le presse papier !");
    },
    openGenerateLinkModale() {
      this.isGenerateLinkModaleOpen = true;
    },
    closeGenerateLinkModale() {
      this.isGenerateLinkModaleOpen = false;
    },
    createCharts() {
      setTimeout(() => {
        let sectorKey = Object.keys(this.chart)[this.tab];
        let roadKey = Object.keys(this.chart[sectorKey])[
          this.subTabs[sectorKey].road
        ];
        let graphs = this.chart[sectorKey][roadKey].filter(
          (el) =>
            el.sens ==
            this.subTabs[sectorKey].sens[this.subTabs[sectorKey].road]
        )[0].graphs;
        graphs.forEach(async (chart) => {
          switch (chart.type) {
            case "XYChart":
              if (chart.dir === "H") {
                await this.createHorizontalChart(
                  sectorKey + "_" + roadKey + "_" + chart.id,
                  chart.data,
                  chart.name,
                  chart.axisLabel
                );
              } else {
                await this.createVerticalChart(
                  sectorKey + "_" + roadKey + "_" + chart.id,
                  chart.data,
                  chart.name,
                  chart.axisLabel
                );
              }
              break;
            case "Pie":
              await this.createPieChart(
                sectorKey + "_" + roadKey + "_" + chart.id,
                chart.data,
                chart.name,
                chart.axisLabel
              );
              break;
            default:
              break;
          }
        });
      }, 200);
    },
    getStatisticTableData() {
      setTimeout(() => {
        let sectorKey = Object.keys(this.chart)[this.tab];
        let roadKey = Object.keys(this.chart[sectorKey])[
          this.subTabs[sectorKey].road
        ];
        let dirKey = this.subTabs[sectorKey].sens[this.subTabs[sectorKey].road];
        const filters = {
          road: roadKey,
          dir: dirKey,
        };
        Object.keys(this.statisticTables).forEach((stIdx) => {
          let statisticTable = this.statisticTables[stIdx];
          this.$api.auscultationCampaigns
            .getStatisticTableData(statisticTable.id, filters)
            .then((data) => {
              if (data && data.name) {
                statisticTable.name = data.name;
                let tableDataTemp = [];
                if (data.rows) {
                  Object.keys(data.rows).forEach((featureName) => {
                    let featureDesc = data.rows[featureName];
                    let dataTableContent = {};
                    if (statisticTable.hasFeatureColumn) {
                      dataTableContent["featureName"] = featureName;
                    }
                    if (statisticTable.hasUnitColumn) {
                      dataTableContent["unitName"] = featureDesc.unit;
                    }
                    let featureData = featureDesc.data;
                    Object.keys(featureData).forEach((dataIndex) => {
                      if (statisticTable.hasDesignationColumn) {
                        dataTableContent["designationName"] =
                          featureData[dataIndex].name;
                      }
                      if (statisticTable.hasResultColumn) {
                        dataTableContent["value"] =
                          featureData[dataIndex].value;
                      }
                      if (statisticTable.hasFreqPercColumn) {
                        dataTableContent["freqPercValue"] =
                          featureData[dataIndex].p_f;
                      }
                      tableDataTemp.push({ ...dataTableContent });
                    });
                  });
                  this.$set(statisticTable, "tableData", tableDataTemp);
                }
              }
            });
        });
      }, 200);
    },
    /**
     * @description create a Pie chart
     * @param {string} ref - the HTML element using its id
     * @param {object} data - the data used for the chart
     * @param {string} title - the title of the chart
     * @returns void
     */
    async createPieChart(ref, data, title) {
      return await amchartUtils.createPieChart(ref, data, title);
    },
    /**
     * @description create a horizontal XY chart
     * @param {string} ref - the HTML element using its id
     * @param {object} data - the data used for the chart
     * @param {string} title - the title of the chart
     * @param {string} text - the axis label of the chart
     * @returns void
     */
    async createHorizontalChart(ref, data, title, text) {
      return await amchartUtils.createHorizontalChart(ref, data, title, text);
    },
    /**
     * @description create a vertical XY chart
     * @param {string} ref - the HTML element using its id
     * @param {object} data - the data used for the chart
     * @param {string} title - the title of the chart
     * @param {string} text - the axis label of the chart
     * @returns void
     */
    async createVerticalChart(ref, data, title, text) {
      return await amchartUtils.createVerticalChart(ref, data, title, text);
    },
    getDataChart() {
      this.$api.auscultationCampaigns
        .getChartData(this.$route.params.id)
        .then((data) => {
          this.chart = data;
          Object.keys(this.chart).forEach((el) => {
            this.$set(this.subTabs, el, {
              road: 0,
              //Setting default values for sens
              sens: Object.keys(this.chart[el]).map(
                (road) => this.chart[el][road][0].sens
              ),
              //Storing default values for sens
              defaultSens: Object.keys(this.chart[el]).map(
                (road) => this.chart[el][road][0].sens
              ),
              //Precalculing if sens select form will be available
              isSelectDisplayed: Object.keys(this.chart[el]).map(
                (road) => this.chart[el][road].length > 1
              ),
            });
          });
          this.createCharts();
          this.getStatisticTables();
          this.getStatisticTableData();
        });
    },
    getStatisticTables() {
      setTimeout(() => {
        let sectorKey = Object.keys(this.chart)[this.tab];
        this.$api.auscultationCampaigns
          .getStatisticTables(this.$route.params.id, sectorKey)
          .then((data) => {
            this.statisticTables = data;
            Object.keys(this.statisticTables).forEach((idx) => {
              this.statisticTables[idx].headers = [];
              this.statisticTables[idx].hasFeatureColumn = false;
              this.statisticTables[idx].hasDesignationColumn = false;
              this.statisticTables[idx].hasUnitColumn = false;
              this.statisticTables[idx].hasResultColumn = false;
              this.statisticTables[idx].hasFreqPercColumn = false;

              if (
                this.statisticTables[idx][COLUMN_NAME_CONSTANT.FEATURE_NAME]
              ) {
                let header = {
                  text: this.statisticTables[idx][
                    COLUMN_NAME_CONSTANT.FEATURE_NAME
                  ],
                  align: "center",
                  value: "featureName",
                  sortable: false,
                };
                this.statisticTables[idx].hasFeatureColumn = true;
                this.statisticTables[idx].headers.push(header);
              }
              if (
                this.statisticTables[idx][COLUMN_NAME_CONSTANT.DESIGNATION_NAME]
              ) {
                let header = {
                  text: this.statisticTables[idx][
                    COLUMN_NAME_CONSTANT.DESIGNATION_NAME
                  ],
                  align: "center",
                  value: "designationName",
                  sortable: false,
                };
                this.statisticTables[idx].hasDesignationColumn = true;
                this.statisticTables[idx].headers.push(header);
              }
              if (this.statisticTables[idx][COLUMN_NAME_CONSTANT.UNIT_NAME]) {
                let header = {
                  text: this.statisticTables[idx][
                    COLUMN_NAME_CONSTANT.UNIT_NAME
                  ],
                  align: "center",
                  value: "unitName",
                  sortable: false,
                };
                this.statisticTables[idx].hasUnitColumn = true;
                this.statisticTables[idx].headers.push(header);
              }
              if (this.statisticTables[idx][COLUMN_NAME_CONSTANT.RESULT_NAME]) {
                let header = {
                  text: this.statisticTables[idx][
                    COLUMN_NAME_CONSTANT.RESULT_NAME
                  ],
                  align: "center",
                  value: "value",
                  sortable: false,
                };
                this.statisticTables[idx].hasResultColumn = true;
                this.statisticTables[idx].headers.push(header);
              }
              if (
                this.statisticTables[idx][COLUMN_NAME_CONSTANT.FREQ_PERC_NAME]
              ) {
                let header = {
                  text: this.statisticTables[idx][
                    COLUMN_NAME_CONSTANT.FREQ_PERC_NAME
                  ],
                  align: "center",
                  value: "freqPercValue",
                  sortable: false,
                };
                this.statisticTables[idx].hasFreqPercColumn = true;
                this.statisticTables[idx].headers.push(header);
              }
            });
          });
      });
    },
    getGraphs(element, sens) {
      if (element.length) {
        let res = element.filter((el) => el.sens == sens)[0];
        if (res) {
          return res.graphs;
        } else return [];
      } else {
        return [];
      }
    },
    resetFilters() {
      let sectorKey = Object.keys(this.chart)[this.tab];
      this.subTabs[sectorKey].sens = [...this.subTabs[sectorKey].defaultSens];
      this.subTabs[sectorKey].road = 0;
      this.createCharts();
      this.getStatisticTables();
      this.getStatisticTableData();
    },
  },
  computed: {
    isDashboardDisplayed() {
      return (
        ["engineering", "finished", "archived"].indexOf(
          this.auscultationCampaign.status
        ) != -1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#auscultation-campaign-dashboard {
  padding: 15px;
}
.filters_containers {
  display: flex;
  flex-direction: row;
}
.filterName {
  width: 300px;
}
.filterDir {
  width: 100px;
  margin-left: 10px;
}
.all_container {
  height: calc(100vh - 200px - 75px);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  .v-card {
    border: 1;
    width: calc(50% - 20px);
    margin: 0 8px 20px;
    padding: 20px;
    background: #fafafa;
    &:nth-child(1),
    &:nth-child(2) {
      .ChartBox {
        height: 450px;
      }
    }
    .ChartBox {
      height: 500px;
    }
    .Loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Commentary {
      span {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        color: rgba(0, 0, 0, 0.6);
      }
      p {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}
.statistic_tables_container {
  display: flex;
  flex-wrap: wrap;
}
.charts_container {
  display: flex;
  flex-wrap: wrap;
}
.message_container {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.generateLinkInput {
  display: flex;
  align-items: center;
  justify-content: space-around;

  & input {
    width: 100%;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
