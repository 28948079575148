// @ts-nocheck
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";

// Config for daisy chaining, see https://www.amcharts.com/docs/v4/concepts/performance/#Daisy_chaining_multiple_charts
am4core.options.queue = true;
am4core.options.deferredDelay = 500;

am4core.options.autoDispose = true;
am4core.options.animationsEnabled = false;
am4core.useTheme(am4themes_material);

const colors = [
    "#BE5900",
    "#FF7800",
    "#FF8B25",
    "#FFA14E",
    "#FFB725",
    "#F79909",
    "#D58100"
];

/*const reRenderSvg = (ref, type = "before") => {
  var chart = document.querySelector(`#${ref}`);
  var box = chart.childNodes[0];
  var svg = chart.childNodes[0].childNodes[0];
  if (svg) {
    if ("after" === type) {
      // remove the attributes after generating canvas
      svg.removeAttribute("width");
      svg.removeAttribute("height");
      svg.removeAttribute("fontSize");
    } else {
      svg.querySelectorAll("tspan").forEach(tspan => {
        tspan.style.fontSize = 12;
        tspan.style.fontFamily = "Roboto";
      });

      // set width and height according to parent container
      svg.setAttribute("width", box.clientWidth);
      svg.setAttribute("height", box.clientHeight);
      svg.setAttribute("fontSize", 24);
    }
  }
};*/

const amchartUtils = {
    /**
     * @description create a Pie chart
     * @param {string} ref - the HTML element using its id
     * @param {object} data - the data used for the chart
     * @param {string} title - the title of the chart
     * @returns void
     */
    createPieChart(ref, data, title) {
        return am4core.createDeferred(() => {
            // CHART
            const ch = am4core.create(ref, am4charts.PieChart);
            ch.data = data;
            ch.logo.disabled = true;
            ch.radius = am4core.percent(66);
            // LEGEND
            ch.legend = new am4charts.Legend();
            ch.legend.position = "right";
            ch.legend.fontSize = 12;
            ch.legend.maxHeight = 50;
            ch.legend.maxWidth = 150;
            ch.legend.labels.template.truncate = false;
            ch.legend.labels.template.align = "left";
            ch.legend.labels.template.textAlign = "start";
            ch.legend.numberFormatter.numberFormat = "#";
            ch.legend.valueLabels.template.text = "";
            ch.legend.valueLabels.template.align = "right";
            ch.legend.valueLabels.template.textAlign = "end";

            // TITLE
            const t = ch.titles.create();
            t.text = title;
            t.fontSize = 18;

            // SERIE
            const sr = ch.series.push(new am4charts.PieSeries());
            sr.dataFields.value = "value";
            sr.dataFields.category = "name";
            sr.slices.template.propertyFields.fill = "color";
            sr.events.on("datavalidated", ev => {
                /**
                 * @description hide data and legend for each slice with value equal to zero
                 * @returns void
                 */
                ev.target.slices.each(slice => {
                    // if slice value is equal to zero
                    if (0 === slice.dataItem.values.value.percent) {
                        // hide slice
                        slice.dataItem.hide();
                        // and its legend
                        slice.dataItem.legendDataItem.hide();
                        slice.dataItem.legendDataItem.height = 0;
                    }
                });
            });

            // reRenderSvg(ref);

            // LABEL
            const lbl = sr.labels.template;
            lbl.maxWidth = 150;
            lbl.wrap = true;
            lbl.text = "{value.percent.formatNumber('#.0')}%";

            return ch;
        });
    },
    setTitle(ch, title) {
        // TITLE
        const t = ch.titles.create();
        t.text = title;
        t.fontSize = 18;
        t.marginBottom = 30;

        // CURSOR
        ch.cursor = new am4charts.XYCursor();
    },
    async createXYChart(ref, data) {
        //return await am4core.createDeferred(() => {
        const ch = am4core.create(ref, am4charts.XYChart);
        ch.data = data;
        ch.logo.disabled = true;
        ch.interpolationDuration = 0;
        ch.startAlpha = 0;
        ch.inversed = true;

        return ch;
        //});
    },
    async createHorizontalChart(ref, data, title, axisLabel) {
        // CHART
        const ch = await this.createXYChart(ref, data.reverse());
        this.setTitle(ch, title);

        // LEGEND
        ch.legend = new am4charts.Legend();
        ch.legend.fontSize = 12;
        ch.legend.marginTop = 20;

        // Y AXIS
        const yax = ch.yAxes.push(new am4charts.CategoryAxis());
        yax.dataFields.category = "type";
        yax.title.text = "";
        yax.renderer.grid.template.location = 0;
        yax.renderer.minGridDistance = 20;
        yax.numberFormatter.numberFormat = "#";

        // X AXIS
        const xax = ch.xAxes.push(new am4charts.ValueAxis());
        xax.title.text = axisLabel;
        xax.numberFormatter.numberFormat = "#";

        // SERIES
        // let i = 0;
        Object.keys(data[0]).forEach(key => {
            if ("type" === key || "color" === key) {
                return;
            }
            const sr = ch.series.push(new am4charts.ColumnSeries());
            sr.dataFields.valueX = key;
            sr.dataFields.categoryY = "type";
            // sr.columns.template.fill = am4core.color(data[index].color);
            // sr.columns.template.stroke = am4core.color(data[index].color);
            sr.name = key;
            sr.tooltipText = "{name}: [bold]{valueX}[/]";
            sr.stacked = true;
            // this.setInfos(sr, i);
        });

        //reRenderSvg(ref);
        return ch;
    },
    async createVerticalChart(ref, data, title, axisLabel) {
        // CHART
        const ch = await this.createXYChart(ref, data);
        ch.inversed = false;
        ch.reverseOrder = false;
        this.setTitle(ch, title);

        // X AXIS
        const xax = ch.xAxes.push(new am4charts.CategoryAxis());
        xax.dataFields.category = "name";
        xax.title.text = "";
        xax.renderer.grid.template.location = 0;
        xax.renderer.minGridDistance = 20;
        xax.numberFormatter.numberFormat = "#";

        // Y AXIS
        const yax = ch.yAxes.push(new am4charts.ValueAxis());
        yax.title.text = axisLabel;
        yax.numberFormatter.numberFormat = "#";

        // X LABEL
        const lb = xax.renderer.labels.template;
        lb.truncate = true;
        lb.maxWidth = 200;
        lb.tooltipText = "{category}";

        /**
         * @description ajust label x position on render
         * @returns void
         */
        xax.events.on("sizechanged", ev => {
            const ax = ev.target;
            const cellWidth = ax.pixelWidth / (ax.endIndex - ax.startIndex);
            if (cellWidth < ax.renderer.labels.template.maxWidth) {
                lb.rotation = -65;
                lb.horizontalCenter = "right";
                lb.verticalCenter = "middle";
            }
        });

        // SERIES
        let i = 0;
        Object.keys(data[0]).forEach(key => {
            if ("name" === key) {
                return;
            }
            const sr = ch.series.push(new am4charts.ColumnSeries());
            sr.dataFields.valueY = key;
            sr.dataFields.categoryX = "name";
            sr.name = key;
            sr.tooltipText = "{name}: [bold]{valueY}[/]";
            this.setInfos(sr, i);
        });
        return ch;
    },
    setInfos(sr, i) {
        sr.tooltip.label.adapter.add("text", (text, target) => {
            return target.dataItem && target.dataItem.valueX === 0 ? "" : text;
        });
        sr.columns.template.fill = am4core.color(colors[i]);
        sr.columns.template.stroke = am4core.color(colors[i]);
        sr.stacked = true;
        i++;
    },
    disposeAll() {
        am4core.disposeAllCharts();
    }
};
export default amchartUtils;
