<template>
  <section class="DashboardTable" style="width: 100%">    
    <v-card flat>
      <v-row>
        <v-col cols="12">
          <h1>{{name}}</h1>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :items-per-page="-1"
            :server-items-length="headers.length"
            update:page
            dense
            hide-default-footer
          ></v-data-table>
        </v-col>
        <v-col cols="12" v-if="footer">
          <v-spacer/>
            <h2>{{ footer }}</h2>
          <v-spacer/>
        </v-col>
      </v-row>
    </v-card>
  </section>
</template>

<script>

export default {
  name: "DashboardTable",
  props: {
    name:{
      type: String,
      required: false,
    },
    headers:{
      type: Array,
      required: true,
    }, tableData:{
      type: Array,
      required: true,
    },
    footer: {
      type: String,
      required: false,
    }},
  data: () => ({}),
  methods: {},
  created() {}
};
</script>

<style lang="scss">
.DashboardTable {
  .v-card {
    margin: 0 20px 20px 0;
    padding: 20px;
    background: #fafafa;
  }
  table {
    width: 100vw;
    background: #fafafa;
    th,
    td {
      padding: 0;
      border-bottom: none;
      border: thin #c6c6c6 solid;
    }
    thead tr {
      background: #2c0703;
      th span {
        color: #fff;
      }
    }
  }
}
</style>
