<template>
  <div class="auscultation_map__menu">
    <v-expansion-panels v-model="isOpen">
      <v-expansion-panel>
        <h3 class="panel-header_overtext">{{ $t("selected_layer") }}</h3>
        <v-expansion-panel-header class="pt-0 pb-0">
          <h4>
            {{ lastItemSelected }}
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-treeview
            class="miniCheckbox"
            :return-object="true"
            selectable
            v-model="selected"
            :items="items"
            selected-color="darkgrey"
            off-icon="mdi-checkbox-blank-outline"
            on-icon="mdi-checkbox-marked"
            item-disabled="locked"
          ></v-treeview>
          <div class="reset_button_container mt-4">
            <medium-button-slot @click="selected = []"
              >Réinitialiser</medium-button-slot
            >
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import MAPLAYER_STATUS from "../../../utils/map/maplayerstatus.const";

export default {
  name: "AuscultationMapMenu",
  props: ["layersList"],
  data: () => ({
    items: [],
    selected: [],
    isOpen: false,
    selectedFirst: false,
  }),
  mounted() {
    this.items = this.getMenuItems(this.layersList);
  },
  watch: {
    selected() {
      this.$emit(
        "layerSelected",
        this.selected.map((el) => el.id)
      );
    },
  },
  computed: {
    lastItemSelected() {
      if (this.selected.length === 0) return "Aucune";
      let lastSelected = this.selected[this.selected.length - 1];
      return this.$cap(lastSelected.name);
    },
  },
  methods: {
    changeExpansionPanel(isOpen) {
      if (isOpen) this.isOpen = 0;
      else this.isOpen = null;
    },
    getMenuItem(layer) {
      let locked = layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED;
      let item = {
        id: layer.jsonKey + "_Id",
        name: this.$cap(layer.name),
        children: this.getMenuItems(layer.children),
        locked: locked,
      };
      if (!locked && !this.selectedFirst) {
        this.selected.push(item);
        this.selectedFirst = true;
      }
      return item;
    },
    getMenuItems(layerList) {
      return layerList.map((el) => {
        return this.getMenuItem(el);
      });
    },
  },
};
</script>

<style lang="scss">
.auscultation_map__menu {
  font-family: Roboto, sans-serif;
  .panel-header_overtext {
    padding: 16px 18px 10px 18px;
    font-size: 20px;
  }
  .v-expansion-panel-header {
    font-size: 15px;
  }
  position: absolute;
  top: 10px;
  left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;
  .reset_button_container {
    display: flex;
    justify-content: center;
  }

  .miniCheckbox .mdi-checkbox-blank-outline::before,
  .miniCheckbox .mdi-checkbox-marked::before {
    transform: scale(0.8) !important;
  }
}
</style>
